<template>
  <div id="idea-home">
    <!-- Content -->
    <div class="container-1200">
      <div class="content">

        <!-- Header -->
        <header class="d-flex justify-content-between flex-column flex-column-reverse flex-xl-row">
          <div>
            <h1>{{ $t('hello') }}, {{ getUserFirstName }} 👋</h1>
            <p class="text-lead">
              {{ $t('letsBringThisIdeaToLife') }}
            </p>
          </div>
        </header>
        <!-- /Header -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Content Wrapper -->
        <div class="content-wrapper">
          <v-app>
            <idea-home-cards class="mb-10" />

            <users class="mb-10" />

            <ideas class="mb-10" />

            <learn-more-and-help-cards />

            <!--            <idea-home-cards-old />-->
          </v-app>
          <!-- Content -->
        </div>
        <!-- /Content Wrapper -->
      </div>
    </div>
    <!-- /Content -->
  </div>
</template>

<script>
import IdeaHomeCards from '@/views/Home/IdeaHome/Components/IdeaHomeCards.vue'
import Ideas from '@/views/Home/IdeaHome/Components/Ideas/Ideas.vue'
import LearnMoreAndHelpCards from '@/views/Home/IdeaHome/Components/LearnMoreAndHelp/LearnMoreAndHelpCards.vue'
import Users from '@/views/Home/IdeaHome/Components/Users/Users.vue'
// import IdeaHomeCardsOld from '@/views/Home/IdeaHome/Components/IdeaHomeCardsOld.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'IdeaHomePage',

  components: {
    Ideas,
    Users,
    LearnMoreAndHelpCards,
    IdeaHomeCards
    // IdeaHomeCardsOld
  },

  computed: {
    ...mapGetters('user', ['getUserFirstName'])
  }
}
</script>

<style scoped>
#idea-home {
  margin-left: 0;
}
</style>
